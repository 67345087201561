import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { isRejected } from '@reduxjs/toolkit'
import Pusher from 'pusher-js'

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
  cluster: 'ap1'
})
export const subscribeAIEMailGeneratingChannel = pusher.subscribe('ai-email-generating')
export const subscribeStripeCheckoutChannel = pusher.subscribe('stripe-checkout')
export const subscribeAutoPilotChannel = pusher.subscribe('auto-pilot')

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action: unknown) => {
    if (isRejected(action)) {
      console.log(action)
    }

    return next(action)
  }
