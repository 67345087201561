import http from '@/utils/http'
import { BaseQueryFn } from '@reduxjs/toolkit/query'
import { AxiosError, AxiosRequestConfig } from 'axios'

type ResponseAxios = {
  url: string
  method?: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  headers?: AxiosRequestConfig['headers']
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

const axiosBaseQuery =
  (): BaseQueryFn<ResponseAxios> =>
  async ({ url, method, data, params, headers }) => {
    try {
      let baseUrl = process.env.REACT_APP_API_URL

      if (headers?.isAISite) baseUrl = process.env.REACT_APP_AI_API_URL
      if (headers?.isMsSite) baseUrl = process.env.REACT_APP_MS_API_URL
      if (headers?.isRuntime) baseUrl = process.env.REACT_APP_GENERAL_RUNTIME
      if (headers?.isDelay) await delay(1000)

      const result = await http({
        url: `${baseUrl}${url}`,
        method,
        data,
        params,
        headers: {
          ...headers,
          'Cache-Control': 'no-cache'
        }
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      }
    }
  }

export default axiosBaseQuery
