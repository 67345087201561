export const RULES = {
  EMPTY: { required: true, message: 'Required' },
  EMPTY_INPUT: {
    required: true,
    whitespace: true,
    message: 'Required'
  },
  EMAIL: {
    type: 'email',
    message: 'Invalid email format. Please try again'
  },
  MAX_LENGTH_100: { max: 100, message: 'Too long. Please try again' },
  MAX_LENGTH_255: { max: 255, message: 'Too long. Please try again' },
  MAX_LENGTH_500: { max: 500, message: 'Too long. Please try again' },
  TAIL_MAIL: {
    pattern: /^@[^\s@]+\.[^\s@]+$/,
    message: 'Invalid domain format. Please try again'
  }
}

export const PAGINATION = {
  PAGE: 1,
  PAGE_SIZE: 10,
  PAGE_MAX: 1000,
  PAGE_SIZE_OPTIONS: ['10', '20', '50', '100'],
  SHOW_SIZE_CHANGER: true
}

export const METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
}

export const CAMPAIGN_STATUS = {
  COMPLETED: 'completed',
  PAUSED: 'paused',
  ACTIVE: 'active',
  STOPPED: 'stopped',
  BLOCK_EMAIL: 'block_email',
  BOUNCED_BACK: 'bounced_back'
}

export const LEAD_IN_CAMPAIGN_STATUS = {
  NOT_ENGAGED: 'Not Engaged',
  ENGAGED: 'Engaged'
}

export const EMAIL_STATUS = {
  BLOCK_EMAIL: {
    title: 'Blocked',
    label: 'Domain blocked'
  },
  BOUNCED_BACK: {
    title: 'Error',
    label: 'Bounced Back'
  }
}

export const ORDER_BY_LOWERCASE = {
  descend: 'DESC',
  ascend: 'ASC'
}

export const ENCRYTION = [
  {
    label: 'SSL',
    value: 'ssl'
  },
  {
    label: 'TLS',
    value: 'tls'
  },
  {
    label: 'None',
    value: 'none'
  }
]

export const PROTOCOL_TYPE = [
  {
    label: 'IMAP',
    value: 'IMAP'
  },
  {
    label: 'SMTP',
    value: 'SMTP'
  }
]

export const TYPE_INPUT = {
  INPUT: 'INPUT',
  INPUT_NUMBER: 'INPUT_NUMBER'
}

export const TYPE_EMAIL = {
  RESPONSED: 'Responsed',
  OPENED: 'Opened',
  SENT: 'Sent'
}

export const REGEX = {
  UUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
}
