import Text from '@/components/ui/Text'
import { Modal as AntModal, Flex } from 'antd'
import st from './styles.module.scss'
import cls from 'classnames'
import Icon from '@/components/ui/Icon'
import { CSSProperties } from 'react'

type Props = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  footer?: React.ReactNode
  children: React.ReactNode
  textHeader: string | React.ReactNode
  width?: number
  isBack?: boolean
  style?: CSSProperties
  closeIcon?: React.ReactNode
  styles?: {
    header?: CSSProperties
    body?: CSSProperties
    footer?: CSSProperties
    mask?: CSSProperties
    wrapper?: CSSProperties
  }
  zIndex?: number
  onClickBack?: () => void
  onClickClose?: () => void
}

const Modal = (props: Props) => {
  const {
    open,
    footer,
    children,
    textHeader,
    width,
    setOpen,
    isBack,
    onClickBack,
    onClickClose,
    style,
    styles,
    closeIcon,
    zIndex
  } = props

  const handleClose = () => {
    if (onClickClose) onClickClose()
    setOpen(false)
  }

  return (
    <AntModal
      style={style}
      styles={styles}
      zIndex={zIndex}
      centered
      destroyOnClose
      maskClosable={false}
      open={open}
      onCancel={handleClose}
      width={width}
      closeIcon={closeIcon}
      title={
        <Flex gap={12} align='center'>
          {isBack && (
            <div className={st['back-button']} onClick={onClickBack}>
              <Icon icon='arrow-left' />
            </div>
          )}
          <Text size='20' content={textHeader} fontWeight={600} color='--secondary-2' />
        </Flex>
      }
      footer={footer}
      className={cls({ [`${st.isFooter}`]: !!footer })}
    >
      {children}
    </AntModal>
  )
}

export default Modal
