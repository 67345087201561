import registerNotification from '@/utils/register/notification'
import registerSetting from '@/utils/register/setting'
import registerApollo from './apollo'
import registerCampaign from './campaign'
import registerChat from './chat'
import registerLead from './lead'
import registerPersona from './persona'
import registerScenario from './scenario'

const register = () => {
  registerLead()
  registerCampaign()
  registerScenario()
  registerSetting()
  registerPersona()
  registerApollo()
  registerChat()
  registerNotification()
}

export default register
