import srAnimation from '@/assets/images/loadingsr.json'
import { Skeleton, Spin } from 'antd'
import type { SpinSize } from 'antd/es/spin'
import Lottie from 'lottie-react'
import st from './styles.module.scss'

type Props = {
  loading: boolean
  children: React.ReactNode
  height?: number
  size?: SpinSize
  isProvider?: boolean
  isAIIcon?: boolean
  type?: 'skeleton'
}

const LoadingWrapper = (props: Props) => {
  const { loading, children, height, size, isProvider, isAIIcon, type } = props

  const renderIcon = () => (
    <Lottie animationData={srAnimation} loop={true} className='indicator-icon' />
  )

  if (loading) {
    if (type) {
      return <Skeleton active />
    }

    return (
      <div className={st.loading__container} style={{ height: height || '100%' }}>
        {isProvider ? (
          <Spin
            size={size}
            style={{ width: '100%' }}
            indicator={isAIIcon ? renderIcon() : <Spin />}
          >
            {children}
          </Spin>
        ) : (
          <Spin
            size={size}
            style={{ width: '100%' }}
            indicator={isAIIcon ? renderIcon() : <Spin />}
          />
        )}
      </div>
    )
  }

  return <>{children}</>
}

export default LoadingWrapper
