import type { DashboardType } from '@/types/dashboard.type'
import type { DataResponse } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../axiosBaseQuery'

export type FilterStatsEmail = {
  type: 'TODAY' | 'LAST_7_DAYS' | 'LAST_4_WEEKS'
  filter: 'NEW_FEED' | 'SUMMARY'
}

type Dashboard = DashboardType.NewsFeed &
  DashboardType.BestPerformingCampaign &
  DashboardType.WelcomeBack &
  DashboardType.KeyInsights

export const dashboardSlice = createApi({
  reducerPath: 'dashboardSlice',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getDashboardData: build.query<DataResponse<Dashboard>, { filter: string }>({
      query: (params) => ({
        url: '/api/user/notification-statistic-email-dashboard',
        params
      }),
      keepUnusedDataFor: 0
    }),
    getStatsEmail: build.query<DataResponse<DashboardType.StatsEmail>, FilterStatsEmail>({
      query: (params) => ({
        url: '/api/user/notification-statistic-email-dashboard',
        params
      }),
      keepUnusedDataFor: 0
    }),
    getSuggestScenario: build.query<DataResponse<DashboardType.SuggestScenario>, null>({
      query: () => ({
        url: '/api/least-used-scenarios'
      })
    })
  })
})

export const { useGetDashboardDataQuery, useGetStatsEmailQuery, useGetSuggestScenarioQuery } =
  dashboardSlice
