import { rtkQueryErrorLogger } from '@/middleware'
import { rootReducer } from '@/store/reducers'
import { campaignSlice } from '@/store/slice/campaign.service'
import { leadSlice } from '@/store/slice/lead.service'
import { scenarioSlice } from '@/store/slice/scenario.service'
import { settingSlice } from '@/store/slice/setting.service'
import { configureStore } from '@reduxjs/toolkit'
import { dashboardSlice } from './slice/dashboard.service'
import { userSlice } from '@/store/slice/user.service'
import { notificationSlice } from '@/store/slice/notification.service'
import { stripeSlice } from '@/store/slice/stripe.service'
// import { setupListeners } from '@reduxjs/toolkit/query'

export const store = configureStore({
  reducer: rootReducer,

  // Add api middleware to enable features like: catching, invalidation, polling of RTK-Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(leadSlice.middleware, rtkQueryErrorLogger)
      .concat(campaignSlice.middleware)
      .concat(scenarioSlice.middleware)
      .concat(settingSlice.middleware)
      .concat(dashboardSlice.middleware)
      .concat(userSlice.middleware)
      .concat(notificationSlice.middleware)
      .concat(stripeSlice.middleware)
})

// Optional, but required if you use features: `refetchOnFocus` or `refetchOnReconnect`
// setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
