import type { ColorVariablesType } from '@/types/utils.type'
import cls from 'classnames'
import React, { CSSProperties, ReactNode } from 'react'
import st from './styles.module.scss'

type Props = {
  size: string
  content: string | number | ReactNode
  fontWeight?: number
  color?: ColorVariablesType
  textTransform?: 'uppercase' | 'capitalize' | 'lowercase' | 'unset'
  fontStyle?: 'italic' | 'unset'
  customClassName?: string
  customStyle?: CSSProperties
  pointer?: boolean
  onClick?: () => void
  maxWidth?: number
  padding?: string | number
  isTextOverflow?: boolean
  isCenter?: boolean
}

const Text = ({
  size,
  content,
  fontWeight = 400,
  color = '--sub-1',
  textTransform = 'unset',
  fontStyle,
  customClassName,
  customStyle,
  pointer,
  onClick,
  maxWidth,
  padding,
  isTextOverflow,
  isCenter
}: Props) => {
  return (
    <p
      className={cls(
        {
          text: true,
          [st[`text-${size}`]]: true,
          [st['text-overflow']]: isTextOverflow
        },
        customClassName
      )}
      onClick={onClick}
      style={{
        ...customStyle,
        userSelect: 'none',
        fontWeight: fontWeight,
        color: `var(${color})`,
        cursor: pointer ? 'pointer' : 'unset',
        textAlign: isCenter ? 'center' : 'unset',
        textTransform,
        fontStyle,
        maxWidth,
        padding
      }}
    >
      {content}
    </p>
  )
}

export default Text
