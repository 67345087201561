import authApiRequest from '@/apis/auth.api'
import Icon from '@/components/ui/Icon'
import LoadingWrapper from '@/components/ui/Loading'
import Text from '@/components/ui/Text'
import { bugsnagNotification } from '@/configs/setupBugSnags'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { logOutUserLoading, onGetUserInfo, resetAuthState } from '@/store/reducers/auth'
import type { PayloadErrorMessage } from '@/types/utils.type'
import { coreError } from '@/utils/helpers'
import type { MenuProps } from 'antd'
import { Avatar, Dropdown, Flex } from 'antd'
import { HttpStatusCode } from 'axios'
// import { useEffect } from 'react'
import { renderExpireDate } from '@/components/layout/Header/utils/helper'
import { confirmModal } from '@/utils/components'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import st from './styles.module.scss'
import { isEmpty } from 'lodash'

const cookie = new Cookies()
const ENV = process.env.REACT_APP_API_URL

const UserAccount = () => {
  const { user } = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      dispatch(logOutUserLoading(true))
      const response = await authApiRequest.logout()
      if (response.status === HttpStatusCode.NoContent) {
        dispatch(resetAuthState())
        dispatch(logOutUserLoading(false))
        localStorage.clear()
        if (user?.info_setting?.user_role?.includes('USER')) {
          cookie.remove('access_token')
          navigate('/')
        }
        if (user?.info_setting?.user_role?.includes('ADMIN')) {
          cookie.remove('access_token_admin')
          navigate('/admin-login')
        }
      }
    } catch (error) {
      bugsnagNotification(coreError(error as PayloadErrorMessage))
    }
  }

  const handleUnsubscribe = () => {
    confirmModal({
      title: 'Confirm',
      content: 'Are you sure you want to cancel subscription?',
      okText: 'Yes',
      okColor: 'red',
      cancelText: 'Close',
      callback: async () => {
        try {
          dispatch(logOutUserLoading(true))
          const response = await authApiRequest.cancelSubscription(user?.id as string)
          if (response) {
            setTimeout(async () => {
              const result = await authApiRequest.me()
              if (result) {
                dispatch(logOutUserLoading(false))
                dispatch(onGetUserInfo(result.data.data))
              }
            }, 5000)
          }
        } catch (error) {
          bugsnagNotification(coreError(error as PayloadErrorMessage))
        }
      }
    })
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <Flex align='flex-start' className={st.user__container}>
          <Avatar
            className={st.user__avatar}
            size={40}
            src={<img src={`${ENV}${user?.avatar}`} alt='avatar' />}
          />
          <div>
            <Text
              size='14'
              content={user?.userName as string}
              fontWeight={600}
              color='--secondary-2'
            />
            <Text size='10' content={user?.userEmail as string} color='--sub-2' />
            <Text size='10' content={renderExpireDate(user!)} color='--secondary-4' />
          </div>
        </Flex>
      ),
      key: '0'
    },
    ...(user?.info_setting?.user_role?.includes('USER') &&
    user?.user_subscription?.status !== 'CANCELED' &&
    !isEmpty(user.user_subscription)
      ? [
          {
            label: <Text size='14' content='Cancel subscription' color='--sub-2' />,
            icon: <Icon icon='unsubcribe' size={14} color='--sub-2' />,
            key: '1',
            onClick: handleUnsubscribe
          }
        ]
      : []),
    {
      label: <Text size='14' content='Sign out' color='--sub-2' />,
      icon: <Icon icon='logout' size={14} color='--sub-2' />,
      key: '2',
      onClick: handleLogout
    }
  ]

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: 'en',
  //       autoDisplay: false
  //     },
  //     'google_translate_element'
  //   )
  // }
  // useEffect(() => {
  //   var addScript = document.createElement('script')
  //   addScript.setAttribute(
  //     'src',
  //     '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  //   )
  //   document.body.appendChild(addScript)
  //   window.googleTranslateElementInit = googleTranslateElementInit
  // }, [])

  return (
    <LoadingWrapper loading={!user} size='small'>
      {/* <div id='google_translate_element'></div> */}
      <Dropdown
        overlayClassName='dropdown'
        menu={{ items }}
        placement='bottomRight'
        trigger={['click']}
      >
        <Flex align='center' className={st.user__container}>
          <Avatar
            className={st.user__avatar}
            size={32}
            src={<img src={`${ENV}${user?.avatar}`} alt='avatar' />}
          />
          <Text size='14' content={user?.userName as string} />
        </Flex>
      </Dropdown>
    </LoadingWrapper>
  )
}

export default UserAccount
