import { Button as AntButton } from 'antd'
import { BaseButtonProps } from 'antd/es/button/button'
import cls from 'classnames'
import st from './styles.module.scss'

type Props = BaseButtonProps & {
  iconNode?: React.ReactNode
  textNode?: React.ReactNode
  color?: 'green' | 'purple' | 'orange' | 'red' | 'default' | 'border--purple'
  clsn?: string
  iconAfter?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
  width?: number
  height?: number
  circle?: boolean
  isCenterLoading?: boolean
  htmlType?: 'button' | 'reset' | 'submit'
}

const Button = (props: Props) => {
  const {
    iconNode,
    textNode,
    color,
    clsn,
    iconAfter,
    onClick,
    width,
    height,
    circle,
    isCenterLoading,
    htmlType,
    ...rest
  } = props

  return (
    <AntButton
      {...rest}
      htmlType={htmlType}
      className={cls(clsn, {
        [st.button]: true,
        [st[`button__${color}`]]: true,
        [st.circle]: circle,
        [st.loading__center]: isCenterLoading
      })}
      style={{ flexDirection: iconAfter ? 'row-reverse' : 'row', width, height }}
      onClick={onClick}
    >
      {iconNode}
      {textNode}
    </AntButton>
  )
}

export default Button
