import srAnimation from '@/assets/images/loadingsr.json'
import Text from '@/components/ui/Text'
import { useAppSelector } from '@/store/hooks'
import { Flex } from 'antd'
import Lottie from 'lottie-react'
import st from './styles.module.scss'

const styles = { height: 32, width: 'auto' }

const Progress = () => {
  const { progressLoading, totalLead, indexLead } = useAppSelector((state) => state.common)

  return progressLoading ? (
    <div className={st.progress}>
      <Flex vertical justify='center' align='center' gap={16} className={st.progress_modal}>
        <Lottie animationData={srAnimation} loop={true} style={styles} />
        <Text
          size='18'
          content={`${indexLead} out of ${totalLead} leads has been processed.`}
          color='--secondary-2'
        />
      </Flex>
    </div>
  ) : null
}

export default Progress
