import { Serializer } from '@/utils/http'

const registerSetting = () => {
  Serializer.register('DEFAULT')
  Serializer.register('ExternalOauthApp', {
    id: 'id'
  })
  Serializer.register('EmailStast', {
    id: 'id'
  })
  Serializer.register('BlockDomain', {
    id: 'id'
  })
  Serializer.register('CompanyOffering', {
    id: 'id'
  })
  Serializer.register('ProductCompany', {
    id: 'id'
  })
  Serializer.register('CaseStudyRef', {
    id: 'id'
  })
  Serializer.register('PainPoint', {
    id: 'id'
  })
  Serializer.register('AboutYou', {
    id: 'id'
  })
  Serializer.register('SubjectLineWorkFlow', {
    id: 'id'
  })
  Serializer.register('EmailOpenerWorkFlow', {
    id: 'id'
  })
  Serializer.register('EmailCloseWorkFlow', {
    id: 'id'
  })
  Serializer.register('CustomEmailSetting', {
    id: 'id'
  })
  Serializer.register('EmailOpener', {
    id: 'id'
  })
  Serializer.register('EmailClose', {
    id: 'id'
  })
  Serializer.register('SubjectLine', {
    id: 'id'
  })
}

export default registerSetting
