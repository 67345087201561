import avatarMichael from '@/assets/images/Michael.jpg'
import Icon from '@/components/ui/Icon'
import Text from '@/components/ui/Text'
import type { Notification as N } from '@/types/notification.type'
import { User } from '@/types/utils.type'
import { isBeforeDate } from '@/utils/helpers'
import { Avatar } from 'antd'
import moment from 'moment'
import st from './../styles.module.scss'

type Category = N.Type['category']

export const renderText = (record: N.Type) => {
  let text: string

  if (record.type === 'EVERY') {
    text = record.message as string
  } else {
    text = `${(record.message as N.Message).greeting} ${
      (record.message as N.Message).emails_opened
    }, ${(record.message as N.Message).responses_received}, ${
      (record.message as N.Message).total_booked_meeting
    }`
  }

  return text
}

export const renderAvatar = (category: Category) => {
  let component: React.ReactNode = (
    <div className={st.user__system}>
      <Icon icon='settings' color='--primary-2' />
    </div>
  )

  if (category === 'CHATBOT') {
    component = (
      <Avatar
        className={st.user__avatar}
        size={32}
        src={<img src={avatarMichael} alt='avatar' />}
      />
    )
  }

  return component
}

export const renderContent = (record: N.Type) => {
  if (
    ['EVERY', 'RECONNECT', 'CHECKOUT_STRIPE', 'CANCEL_SUBSCRIPTION', 'TRIAL_SUBSCRIPTION'].includes(
      record.type
    )
  ) {
    return <span className={st['text-content']}>{record.message as string}</span>
  } else {
    return <span className={st['text-content']}>{renderText(record).substring(0, 80)} ... </span>
  }
}

export const renderDetail = (record: N.Type) => {
  if (record.type === 'EVERY') {
    return (
      <div className={st['modal-body']}>
        <Text content={record.message as string} size='14' />
      </div>
    )
  } else {
    return (
      <div className={st['modal-body']}>
        <p>{(record?.message as N.Message).greeting}</p>
        <ul>
          <li>{(record?.message as N.Message).emails_opened}</li>
          <li>{(record?.message as N.Message).responses_received}</li>
          <li>{(record?.message as N.Message).total_booked_meeting}</li>
          <ul>
            {(record?.message as N.Message).meetings_booked.map((i, idx) => (
              <li key={idx}>
                {i.booked_list} booked for{' '}
                <span style={{ color: 'var(--blue)' }}>{i.campaign_name}</span>
              </li>
            ))}
          </ul>
        </ul>
      </div>
    )
  }
}

export const calculateTimeDifferenceAgo = (
  startTime: moment.Moment,
  endTime: moment.Moment
): string => {
  // Calculate the time difference in minutes
  const diffInMinutes = endTime.diff(startTime, 'minutes')

  if (diffInMinutes < 60) {
    // If the difference is less than 60 minutes, return in minutes ago
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`
  } else if (diffInMinutes < 1440) {
    // Less than 24 hours (1440 minutes)
    // Calculate the time difference in hours
    const diffInHours = Math.floor(diffInMinutes / 60)

    // Return the time difference in hours ago
    return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`
  } else {
    // Calculate the time difference in days
    const diffInDays = Math.floor(diffInMinutes / 1440) // 1440 minutes in a day

    // Return the time difference in days ago
    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`
  }
}

export const renderExpireDate = (user: User) => {
  if (!user) return
  if (user.info_setting?.user_role?.includes('ADMIN')) return
  if (user.status === 'INACTIVE') return
  if (isBeforeDate(user.user_subscription_product?.expired_date!)) return

  if (!isBeforeDate(user?.date_package_trial_expired)) {
    const date = moment(user?.date_package_trial_expired).format('YYYY-MM-DD')

    return `Free trial until ${date}`
  } else {
    const date = moment(user?.user_subscription_product?.expired_date).format('YYYY-MM-DD')
    const packageName = user?.user_subscription_product?.name

    return `${packageName} until ${date}`
  }
}
