import { convertResponseAxios, isUnauthorizedError } from '@/utils/helpers'
import register from '@/utils/register'
import axios, {
  AxiosError,
  AxiosInstance,
  // AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios'
import JSONAPISerializer from 'json-api-serializer'
import Cookies from 'universal-cookie'

export const Serializer = new JSONAPISerializer()

const cookie = new Cookies()
const options = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
}

// let optionsRoot: AxiosRequestHeaders & {
//   isNotification?: boolean
// }

register()

const HttpInstance = (): AxiosInstance => {
  const instance = axios.create(options)

  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      // optionsRoot = config.headers
      const accessTokenUser = cookie.get('access_token')
      const accessTokenAdmin = cookie.get('access_token_admin')

      if (!config.headers.isRuntime) {
        if (accessTokenUser) config.headers['Authorization'] = `Bearer ${accessTokenUser}`
        if (accessTokenAdmin) config.headers['Authorization'] = `Bearer ${accessTokenAdmin}`
      }

      return config
    },
    (error) => error
  )

  instance.interceptors.response.use(
    (response: AxiosResponse) => convertResponseAxios(response),
    (error: AxiosError) => {
      if (isUnauthorizedError(error.response)) {
        cookie.remove('access_token')
        cookie.remove('access_token_admin')
        localStorage.clear()

        if (window.location.pathname !== '/admin-login') {
          window.location.replace('/')
        }
      }

      return Promise.reject(error)
    }
  )

  return instance
}

const http = HttpInstance()

export default http
