import authReducer from '@/store/reducers/auth'
import chatReducer from '@/store/reducers/chat'
import commonReducer from '@/store/reducers/common'
import { campaignSlice } from '@/store/slice/campaign.service'
import { dashboardSlice } from '@/store/slice/dashboard.service'
import { leadSlice } from '@/store/slice/lead.service'
import { notificationSlice } from '@/store/slice/notification.service'
import { scenarioSlice } from '@/store/slice/scenario.service'
import { settingSlice } from '@/store/slice/setting.service'
import { userSlice } from '@/store/slice/user.service'
import { combineReducers } from '@reduxjs/toolkit'
import campaignReducer from './campaign'
import leadReducer from './lead'
import { stripeSlice } from '@/store/slice/stripe.service'

export const rootReducer = combineReducers({
  lead: leadReducer,
  campaign: campaignReducer,
  auth: authReducer,
  chat: chatReducer,
  common: commonReducer,
  [leadSlice.reducerPath]: leadSlice.reducer,
  [campaignSlice.reducerPath]: campaignSlice.reducer,
  [scenarioSlice.reducerPath]: scenarioSlice.reducer,
  [settingSlice.reducerPath]: settingSlice.reducer,
  [dashboardSlice.reducerPath]: dashboardSlice.reducer,
  [userSlice.reducerPath]: userSlice.reducer,
  [notificationSlice.reducerPath]: notificationSlice.reducer,
  [stripeSlice.reducerPath]: stripeSlice.reducer
})
