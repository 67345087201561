import type { User } from '@/types/utils.type'
import BugsnagPerformance from '@bugsnag/browser-performance'
import Bugsnag, { NotifiableError } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const user = JSON.parse(localStorage.getItem('userInfo')!) as User

export const setupBugSnags = () => {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY ?? '',
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
      event.addMetadata('user', {
        id: user.id,
        name: user.userName,
        email: user.userEmail
      })
    },
    releaseStage: process.env.REACT_APP_CURRENT_NODE_ENV ?? 'dev'
  })
  BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_KEY ?? '' })
}

export const bugsnagNotification = (error: NotifiableError) => {
  Bugsnag.notify(error)
}
