import logo from '@/assets/images/logo.svg'
import Notification from '@/components/layout/Header/notification'
import UserAccount from '@/components/layout/Header/user'
import { Flex } from 'antd'
import st from './styles.module.scss'

const Header = () => {
  return (
    <header className={st.header}>
      <img src={logo} alt='logo' />
      <Flex align='center' gap={24}>
        <Notification />
        <UserAccount />
      </Flex>
    </header>
  )
}

export default Header
