import { Serializer } from '@/utils/http'

const registerScenario = () => {
  Serializer.register('Scenario', {
    id: 'id'
  })
  Serializer.register('Scenarios', {
    id: 'id'
  })
  Serializer.register('FollowUpQuestion', {
    id: 'id'
  })
  Serializer.register('ScenariosSubject', {
    id: 'id'
  })
}

export default registerScenario
