import {
  renderAvatar,
  renderContent,
  renderDetail,
  renderText
} from '@/components/layout/Header/utils/helper'
import Icon from '@/components/ui/Icon'
import LoadingWrapper from '@/components/ui/Loading'
import Modal from '@/components/ui/Modal'
import Text from '@/components/ui/Text'
import { useAppSelector } from '@/store/hooks'
import { useGetNotificationsQuery } from '@/store/slice/notification.service'
import type { Notification as N } from '@/types/notification.type'
import { Options } from '@/types/utils.type'
import { Badge, Empty, Flex, Popover } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import st from './styles.module.scss'

const Notification = () => {
  const { user } = useAppSelector((state) => state.auth)

  const [options, setOptions] = useState<Options>({ page: 1, limit: 5 })
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openPopover, setOpenPopover] = useState<boolean>(false)
  const [detail, setDetail] = useState<N.Type>()

  const { data, isFetching } = useGetNotificationsQuery(options, {
    skip: user?.status === 'EXPIRED'
  })
  const [dot, setDot] = useState<boolean>(isEmpty(data))

  const onShow = (record: N.Type) => {
    setOpenModal(true)
    setOpenPopover(false)
    setDetail(record)
  }

  const content = (
    <LoadingWrapper loading={isFetching} height={550}>
      {!isEmpty(data?.data) ? (
        data?.data.map((n) => (
          <Flex key={n.id} gap={8} className={st['notification-item']}>
            <div>{renderAvatar(n.category)}</div>
            <Flex vertical gap={4}>
              <div>
                {renderContent(n)}
                {renderText(n).length > 80 && (
                  <span className={st['text-seemore']} onClick={() => onShow(n)}>
                    see more
                  </span>
                )}
              </div>
              <Text content={moment(n.created_at).utc().format('MMMM D, HH:mm')} size='12' />
            </Flex>
          </Flex>
        ))
      ) : (
        <Flex justify='center' style={{ padding: 12 }}>
          <Empty description='No notifications' />
        </Flex>
      )}
    </LoadingWrapper>
  )

  return (
    <>
      <Modal open={openModal} setOpen={setOpenModal} width={700} textHeader='Detail' footer={false}>
        {detail && renderDetail(detail)}
      </Modal>
      <Popover
        overlayClassName={st.notification}
        content={content}
        open={openPopover}
        title={
          data?.pagination?.total! > options.limit &&
          !isFetching && (
            <Text
              content='SHOW MORE'
              size='12'
              color='--primary-2'
              fontWeight={600}
              isCenter
              onClick={() =>
                setOptions((prev) => ({
                  ...prev,
                  limit: prev.limit + 5
                }))
              }
            />
          )
        }
        trigger='click'
      >
        {user?.status === 'ACTIVE' && (
          <Badge dot={dot}>
            <Icon
              icon='notification'
              pointer
              onClick={() => {
                setOpenPopover(!openPopover)
                setDot(false)
              }}
            />
          </Badge>
        )}
      </Popover>
    </>
  )
}

export default Notification
