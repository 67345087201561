import type { LeadType } from '@/types/lead.type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type State = {
  leadList: LeadType[]
}

const initialState: State = {
  leadList: []
}

const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    resetLeadState: (state) => {
      state.leadList = []
    },
    onAddExistLead: (state, action: PayloadAction<LeadType[]>) => {
      state.leadList = action.payload
    },
    onAddLeadManually: (state, action: PayloadAction<LeadType>) => {
      state.leadList = state.leadList.concat(action.payload)
    }
  },
  extraReducers: () => {}
})

export const { resetLeadState, onAddExistLead, onAddLeadManually } = leadSlice.actions

const leadReducer = leadSlice.reducer
export default leadReducer
