import type { User } from '@/types/utils.type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

type State = {
  user: User | null
  loading: boolean
  logOutLoading: boolean
}

const initialState: State = {
  user: null,
  loading: true,
  logOutLoading: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.user = null
    },
    onGetUserInfo: (state, action: PayloadAction<User>) => {
      state.user = action.payload
      state.loading = isEmpty(action.payload)
    },
    logOutUserLoading: (state, action) => {
      state.logOutLoading = action.payload
    }
  },
  extraReducers: () => {}
})

export const { onGetUserInfo, resetAuthState, logOutUserLoading } = authSlice.actions

const authReducer = authSlice.reducer
export default authReducer
