import Layout from '@/layout'
import AdminLayout from '@/pages/AdminLayout'
import PublicLayout from '@/pages/PublicLayout'
import { retry } from '@/utils/helpers'
import loadable from '@loadable/component'
import * as React from 'react'
import { useLocation, useParams, useRoutes } from 'react-router-dom'
import { OptionalRoute, routes } from './index'

interface PartialRouteObject {
  caseSensitive?: boolean
  children?: PartialRouteObject[]
  element: React.ReactNode
  path: string
}
interface AsyncComponentProps {
  name: string
  privateRouter: boolean
}

const AsyncComponent: React.FC<AsyncComponentProps> = ({ name, privateRouter, ...props }) => {
  const location = useLocation()
  const params = useParams()
  const Component = loadable(() => retry(() => import(`../pages/${name}.tsx`)))

  if (privateRouter) {
    return (
      <Layout params={params} location={location} privateRouter={privateRouter}>
        <AdminLayout>
          <Component {...props} />
        </AdminLayout>
      </Layout>
    )
  } else {
    return (
      <Layout params={params} location={location} privateRouter={privateRouter}>
        <PublicLayout>
          <Component {...props} match={{ params }} location={location} />
        </PublicLayout>
      </Layout>
    )
  }
}

const generateRoutes = (routes: OptionalRoute[]): PartialRouteObject[] => {
  return routes.map(({ path, componentName, privateRouter = false }) => {
    return {
      element: <AsyncComponent name={componentName} privateRouter={privateRouter} />,
      path
    }
  })
}

export default function RenderRouter() {
  return useRoutes([...generateRoutes(routes)])
}
