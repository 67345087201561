import Icon from '@/components/ui/Icon'
import st from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom'
import cls from 'classnames'
import { Tooltip } from 'antd'
import Text from '@/components/ui/Text'

import avatarMichael from '@/assets/images/Michael.jpg'

const Sidebar = () => {
  const { pathname } = useLocation()

  const SIDEBAR = [
    { id: 1, icon: 'dashboard', title: 'Dashboard', url: '/dashboard' },
    { id: 2, title: 'Chat', url: '/chat' },
    { id: 3, icon: 'lead', title: 'Lead Management', url: '/lead' },
    { id: 4, icon: 'campaign', title: 'Campaign Management', url: '/campaign' },
    { id: 5, icon: 'settings', title: 'Settings', url: '/settings' }
  ]

  return (
    <aside className={st.sidebar}>
      {SIDEBAR.map((item) => (
        <Tooltip
          key={item.id}
          color='white'
          placement='right'
          title={<Text size='12' content={item.title} color='--secondary-2' />}
        >
          <Link
            to={item.url}
            className={cls({
              [`${st.sidebar__item}`]: true,
              [`${st.active}`]: pathname.includes(item.url)
            })}
          >
            {item.icon ? (
              <Icon icon={item.icon} size={21} color='--sub-2' />
            ) : (
              <img
                src={avatarMichael}
                alt='avatar michael'
                style={{ width: 24, height: 24, borderRadius: '50%' }}
              />
            )}
          </Link>
        </Tooltip>
      ))}
    </aside>
  )
}

export default Sidebar
