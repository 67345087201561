import { UserAccount } from '@/types/user.type'
import type { DataResponse, User, authResponse } from '@/types/utils.type'
import http from '@/utils/http'

const authApiRequest = {
  loginLinkedin: (token: string, redirect_uri: string) => {
    return http.get<DataResponse<authResponse>>('/api/auth/linkedin', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        redirect_uri,
        local_time: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    })
  },
  logout: () => {
    return http.post('/api/auth/logout')
  },
  loginAdmin: (body: UserAccount.Login) => {
    return http.post<UserAccount.Login, DataResponse<UserAccount.Token>>('/api/admin/login', body)
  },
  me: (token?: string) => {
    return http.get<DataResponse<User>>('/api/auth/me', {
      headers: { Authorization: `Bearer ${token}` }
    })
  },
  cancelSubscription: (user_id: string) => {
    return http.post('/api/stripe/cancel-subscription', { user_id })
  }
}

export default authApiRequest
