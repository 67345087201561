import { useRuntime } from '@voiceflow/react-chat'
import { createNanoEvents } from 'nanoevents'
import { createContext, useMemo } from 'react'
import Cookies from 'universal-cookie'
import { LiveAgentPlatform } from './../utils/shared/live-agent-platform.enum'
import { TalkToAgentTrace } from './../utils/traces/talk-to-agent.trace'
import { useAppSelector } from './hooks'

const cookie = new Cookies()

export interface RuntimeEvents {
  live_agent: (platform: LiveAgentPlatform) => void
}

export interface RuntimeContextValue {
  runtime: ReturnType<typeof useRuntime>
  subscribe: <K extends keyof RuntimeEvents>(event: K, callback: RuntimeEvents[K]) => void
}

export const RuntimeContext = createContext<RuntimeContextValue | null>(null)

export const RuntimeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useAppSelector((state) => state.auth)

  const access_token = cookie.get('access_token')
  const emitter = useMemo(() => createNanoEvents<RuntimeEvents>(), [])

  const runtime = useRuntime({
    url: process.env.REACT_APP_GENERAL_RUNTIME,
    versionID: 'production',
    verify: { projectID: process.env.REACT_APP_VOICEFLOW_PROJECT_ID as string },
    session: { userID: `${user?.id}` },
    traces: [TalkToAgentTrace((platform) => emitter.emit('live_agent', platform))],
    launch: {
      event: {
        type: 'launch',
        payload: {
          access_token
        }
      }
    }
  } as any)

  const subscribe = (event: keyof RuntimeEvents, callback: (data?: any) => void) =>
    emitter.on(event, callback)

  return (
    <RuntimeContext.Provider value={{ runtime, subscribe }}>{children}</RuntimeContext.Provider>
  )
}
