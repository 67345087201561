import { Serializer } from '@/utils/http'

const registerLead = () => {
  Serializer.register('Lead', {
    id: 'id'
  })
  Serializer.register('LeadHistory', {
    id: 'id'
  })
}

export default registerLead
