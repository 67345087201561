import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  messages: []
}

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    onChatbot: (state, action) => {
      state.messages = action.payload
    }
  }
})

export const { onChatbot } = chatSlice.actions

const chatReducer = chatSlice.reducer
export default chatReducer
