import image from '@/assets/images/expired.svg'
import Button from '@/components/ui/Button'
import Icon from '@/components/ui/Icon'
import Text from '@/components/ui/Text'
import { ConfirmModalType } from '@/types/utils.type'
import { Flex, Modal } from 'antd'

export const confirmModal = ({
  title,
  content,
  contentNode,
  loading,
  okText,
  okColor,
  cancelText,
  callback,
  afterClose,
  isFooter = true
}: ConfirmModalType) => {
  const handleOk = () => {
    if (callback) callback()
    if (!loading) Modal.destroyAll()
  }

  Modal.confirm({
    title: <Text size='16' content={title} fontWeight={600} color='--secondary-2' />,
    content: contentNode || <Text size='14' content={content as string} color='--sub-1' />,
    closable: true,
    centered: true,
    afterClose: afterClose,
    className: 'confirm-modal',
    footer: isFooter ? (
      <Flex style={{ marginTop: 24 }} justify='flex-end' align='center' gap={12}>
        <Button
          textNode={<Text size='14' content={cancelText || 'No'} />}
          color='default'
          onClick={Modal.destroyAll}
          loading={loading}
        />
        <Button
          textNode={<Text size='14' content={okText || 'Yes'} />}
          color={okColor || 'purple'}
          onClick={handleOk}
          loading={loading}
        />
      </Flex>
    ) : null
  })
}

export const labelTooltip = (text: string, tooltipText: string) => (
  <Flex align='center' gap={4}>
    <Text size='14' content={text} color='--secondary-2' />
    <Icon icon='about' size={14} tooltipNode={tooltipText} pointer />
  </Flex>
)

export const renderExpiredModal = (action: () => void) => {
  const onClick = () => {
    if (action) action()
    Modal.destroyAll()
  }

  Modal.success({
    centered: true,
    className: 'expired-modal',
    footer: null,
    content: (
      <Flex vertical align='center'>
        <img src={image} alt='expired' />
        <Text
          content='To continue using our services, please upgrade to a paid subscription plan.'
          size='14'
          isCenter
          customStyle={{ margin: 20 }}
        />
        <Button
          textNode={<Text content='Upgrade Now' size='14' />}
          color='purple'
          onClick={onClick}
        />
      </Flex>
    )
  })
}
