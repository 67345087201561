import Header from '@/components/layout/Header'
import NotifyProvider from '@/components/layout/NotifyProvider'
import Progress from '@/components/layout/Progress'
import Sidebar from '@/components/layout/Sidebar'
import LoadingWrapper from '@/components/ui/Loading'
import User from '@/pages/user'
import { RuntimeProvider } from '@/store/context'
import { useAppSelector } from '@/store/hooks'
import { renderExpiredModal } from '@/utils/components'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import st from './styles.module.scss'

type Props = {
  children: React.ReactNode | React.JSX.Element
}

const AdminLayout = ({ children }: Props) => {
  const { user, logOutLoading } = useAppSelector((state) => state.auth)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      user?.id &&
      user.status === 'EXPIRED' &&
      pathname !== '/pricing' &&
      !user.info_setting?.user_role?.includes('ADMIN')
    ) {
      const action = () => navigate('/pricing')
      renderExpiredModal(action)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, pathname])

  return (
    <LoadingWrapper loading={logOutLoading || !user} isProvider>
      {/* {user?.id && user.status === 'EXPIRED' && pathname !== '/pricing' && (
        <div className={st['expire-overlay']} />
      )} */}
      <RuntimeProvider>
        <Progress />
        <Header />
        <NotifyProvider />
        <main className={st.space__dashboard}>
          {user?.info_setting?.user_role?.includes('ADMIN') && (
            <section className={st.layout__admin}>
              <User />
            </section>
          )}
          {user?.info_setting?.user_role?.includes('USER') && (
            <>
              {pathname !== '/pricing' && <Sidebar />}
              <section className={st.layout__admin}>{children}</section>
            </>
          )}
        </main>
      </RuntimeProvider>
    </LoadingWrapper>
  )
}

export default AdminLayout
