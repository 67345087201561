import React from 'react'
import st from './styles.module.scss'

type Props = {
  children: React.ReactNode
}

const PublicLayout = ({ children }: Props) => {
  return (
    <main className={st.space__public}>
      {children}
    </main>
  )
}

export default PublicLayout
