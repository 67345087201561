import { Serializer } from '@/utils/http'

const registerCampaign = () => {
  Serializer.register('CampaignManagement', {
    id: 'id'
  })
  Serializer.register('CampaignEmailLeadMap', {
    id: 'id'
  })
  Serializer.register('CampaignFollowUpQuestion', {
    id: 'id'
  })
  Serializer.register('CampaignEmail', {
    id: 'id'
  })
  Serializer.register('TypeAnswer', {
    id: 'id'
  })
}

export default registerCampaign
