import axiosBaseQuery from '@/store/axiosBaseQuery'
import type { Stripe } from '@/types/stripe.type'
import type { DataResponse } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'

export const stripeSlice = createApi({
  reducerPath: 'stripeSlice',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    postPaymentLink: build.mutation<DataResponse<Stripe.GetPaymentLink>, Stripe.PostPaymentLink>({
      query: (body) => ({
        url: '/api/stripe/payment-link',
        method: 'POST',
        data: body
      })
    }),
    updateUserStatus: build.mutation<DataResponse<any>, any>({
      query: (body) => ({
        url: `/api/admin/update-user-status/${body.id}`,
        method: 'PUT',
        data: body
      })
    }),
    unsubscribe: build.query<DataResponse<any>, Stripe.Unsubscribe>({
      query: (params) => ({
        url: `/api/unsubscribe`,
        params
      })
    })
  })
})

export const { usePostPaymentLinkMutation, useUpdateUserStatusMutation, useLazyUnsubscribeQuery } =
  stripeSlice
