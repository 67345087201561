import type { ScenarioPayload, ScenarioSubjectType, ScenarioType } from '@/types/scenario.type'
import type { DataResponse } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../axiosBaseQuery'
import { Compose } from '@/types/campaign.type'

export const scenarioSlice = createApi({
  reducerPath: 'scenarioSlice',
  tagTypes: ['Scenarios'],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getScenarios: build.query<DataResponse<ScenarioType[]>, void>({
      query: () => ({
        url: '/api/scenarios'
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Scenarios' as const, id: id as string })),
              { type: 'Scenarios' as const, id: 'LIST' }
            ]
          : [{ type: 'Scenarios' as const, id: 'LIST' }]
    }),
    getSubjectScenario: build.query<DataResponse<ScenarioSubjectType>, string>({
      query: (id) => ({
        url: `/api/scenarios-subjects/${id}`
      }),
      keepUnusedDataFor: 0
    }),
    addSubjectScenario: build.mutation<DataResponse<ScenarioSubjectType>, ScenarioPayload.Add>({
      query: (body) => ({
        url: '/api/scenarios-subjects',
        method: 'POST',
        data: body
      }),
      invalidatesTags: (result, error, body) => (error ? [] : [{ type: 'Scenarios', id: 'LIST' }])
    }),
    updateSubjectScenario: build.mutation<DataResponse<ScenarioSubjectType>, ScenarioPayload.Add>({
      query: (body) => ({
        url: `/api/scenarios-subjects/${body.id}`,
        method: 'PUT',
        data: body
      }),
      invalidatesTags: (result, error, body) => (error ? [] : [{ type: 'Scenarios', id: 'LIST' }])
    }),
    generateEmail: build.mutation<Compose.Response | string, any>({
      query: (body) => ({
        url: '/api/v1/email/compose',
        method: 'POST',
        data: body,
        headers: {
          isAISite: true
        }
      })
    })
  })
})

export const {
  useGetScenariosQuery,
  useGetSubjectScenarioQuery,
  useAddSubjectScenarioMutation,
  useUpdateSubjectScenarioMutation,
  useGenerateEmailMutation
} = scenarioSlice
