import type { CampaignPayload, CampaignType } from '@/types/campaign.type'
import type { Product } from '@/types/setting.type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type State = {
  campaignList: CampaignType[]
  campaignItem: CampaignType | null
  campaignFormData: CampaignPayload.Add | null
  productData: Product.Services | null
  isAutoPilot: boolean
  isCustomEmail: boolean
}

const initialState: State = {
  campaignList: [],
  campaignItem: null,
  campaignFormData: null,
  productData: null,
  isAutoPilot: false,
  isCustomEmail: false
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    resetCampaignState: (state) => {
      state.campaignList = []
      state.campaignItem = null
      state.campaignFormData = null
    },
    onAddListCampaign: (state, action: PayloadAction<CampaignType[]>) => {
      state.campaignList = action.payload
    },
    onAddExistCampaign: (state, action: PayloadAction<CampaignType>) => {
      state.campaignItem = action.payload
    },
    onFillCampaignData: (state, action: PayloadAction<CampaignPayload.Add>) => {
      state.campaignFormData = action.payload
    },
    onAddProductData: (state, action: PayloadAction<Product.Services | null>) => {
      state.productData = action.payload
    },
    onCheckAutoPilot: (state, action: PayloadAction<boolean>) => {
      state.isAutoPilot = action.payload
    },
    onCheckCustomEmail: (state, action: PayloadAction<boolean>) => {
      state.isCustomEmail = action.payload
    }
  },
  extraReducers: () => {}
})

export const {
  resetCampaignState,
  onAddListCampaign,
  onAddExistCampaign,
  onFillCampaignData,
  onAddProductData,
  onCheckAutoPilot,
  onCheckCustomEmail
} = campaignSlice.actions

const campaignReducer = campaignSlice.reducer
export default campaignReducer
