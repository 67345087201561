import Button from '@/components/ui/Button'
import Text from '@/components/ui/Text'
import type { UserAccount } from '@/types/user.type'
import { Tag } from 'antd'

export const renderSubscriptionStatus = (
  status: UserAccount.Type['subscription_status'] | UserAccount.Type['subscription_type']
) => {
  let component

  switch (status) {
    case 'TRIAL':
      component = <Tag color='warning'>Trial</Tag>
      break
    case 'CANCELED':
      component = <Tag color='error'>Cancelled</Tag>
      break
    case 'MONTH':
      component = <Tag color='processing'>Month</Tag>
      break
    case 'YEAR':
      component = <Tag color='processing'>Year</Tag>
      break
    default:
      break
  }

  return component
}

export const renderStatus = (status: UserAccount.Type['status']) => {
  let component

  switch (status) {
    case 'ACTIVE':
      component = <Tag color='success'>Active</Tag>
      break
    case 'INACTIVE':
      component = <Tag color='error'>Inactive</Tag>
      break
    default:
      break
  }

  return component
}

export const renderButton = (
  record: UserAccount.Type,
  action: (record: UserAccount.Type) => void
) => {
  let component

  switch (record.status) {
    case 'ACTIVE':
      component = (
        <Button
          textNode={<Text content='Deactivate' size='14' color='--light' />}
          color='red'
          onClick={() => action(record)}
        />
      )
      break
    case 'INACTIVE':
      component = (
        <Button
          textNode={<Text content='Activate' size='14' color='--light' />}
          color='green'
          onClick={() => action(record)}
        />
      )
      break
    default:
      break
  }

  return component
}

export const renderText = (status: UserAccount.Type['status']) =>
  status === 'ACTIVE' ? (
    <p>
      Are you sure want to <span style={{ color: 'var(--secondary-5)' }}>deactivate</span> this
      user?
    </p>
  ) : (
    <p>
      Are you sure want to <span style={{ color: 'var(--secondary-4)' }}>activate</span> this user?
    </p>
  )
