import type { ColorVariablesType } from '@/types/utils.type'
import { Tooltip } from 'antd'
import type { PresetColorType } from 'antd/es/_util/colors'
import type { LiteralUnion } from 'antd/es/_util/type'
import cls from 'classnames'
import { ReactNode } from 'react'

type Props = {
  icon: string
  size?: number
  color?: ColorVariablesType
  pointer?: boolean
  disabled?: boolean
  classNames?: string
  tooltipNode?: ReactNode
  tooltipColor?: LiteralUnion<PresetColorType>
  onClick?: (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

const Icon = ({
  icon,
  size = 16,
  color = '--sub-1',
  pointer,
  tooltipNode,
  tooltipColor,
  disabled = false,
  classNames,
  onClick
}: Props) => {
  const chooseTypeCursor = () => {
    if (disabled) {
      return 'not-allowed'
    } else {
      return pointer ? 'pointer' : 'unset'
    }
  }

  if (tooltipNode) {
    return (
      <Tooltip title={tooltipNode} color={tooltipColor}>
        <span
          className={`icon icon-${icon}`}
          style={{
            fontSize: `${size}px`,
            color: `var(${color})`,
            cursor: chooseTypeCursor()
          }}
          onClick={onClick}
        />
      </Tooltip>
    )
  }

  return (
    <span
      className={cls(`icon`, classNames, {
        [`icon-${icon}`]: icon
      })}
      style={{
        fontSize: `${size}px`,
        color: `var(${color})`,
        cursor: chooseTypeCursor()
      }}
      onClick={onClick}
    />
  )
}

export default Icon
