import axiosBaseQuery from '@/store/axiosBaseQuery'
import type {
  CampaignFormData,
  CampaignPayload,
  CampaignType,
  DetailLeadInCampaign,
  FilterCampaign,
  LeadInCampaignType,
  LeadToCampaignPayload,
  MailStatusPayload
} from '@/types/campaign.type'
import type { LeadType } from '@/types/lead.type'
import type { DataResponse, Params } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'
import { omit } from 'lodash'

export const campaignSlice = createApi({
  reducerPath: 'campaignSlice',
  tagTypes: ['Campaigns', 'LeadInCampaign'],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getCampaigns: build.query<DataResponse<CampaignType[]>, FilterCampaign>({
      query: (params) => ({
        url: '/api/campaigns',
        params
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => {
        if (result) {
          const final = [
            ...result.data.map(({ id }) => ({ type: 'Campaigns' as const, id: id as string })),
            { type: 'Campaigns' as const, id: 'LIST' }
          ]
          return final
        }
        return [{ type: 'Campaigns' as const, id: 'LIST' }]
      }
    }),
    getDetailCampaign: build.query<DataResponse<CampaignType>, Params>({
      query: (params) => ({
        url: `/api/campaigns/${params.id}`,
        params
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) => [{ type: 'Campaigns', id: result?.data.id as string }]
    }),
    addCampaign: build.mutation<DataResponse<CampaignType>, CampaignFormData>({
      query: (body) => {
        return {
          url: '/api/campaigns',
          method: 'POST',
          data: body
        }
      }
    }),
    addLeadToCampaign: build.mutation<DataResponse<LeadType>, LeadToCampaignPayload>({
      query: (body) => ({
        url: '/api/add-lead-to-campaigns',
        method: 'POST',
        data: body
      }),
      invalidatesTags: (_, error) => (error ? [] : [{ type: 'Campaigns', id: 'LIST' }])
    }),
    getDetailLeadInCampaign: build.query<DataResponse<LeadInCampaignType[]>, FilterCampaign>({
      query: (params) => ({
        url: `/api/leads-to-campaign-analytics/${params.id}`,
        params: omit(params, 'id')
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, params) => [{ type: 'LeadInCampaign', id: params.id }]
    }),
    getDetailChart: build.query<any, string>({
      query: (id) => ({
        url: `/api/campaign-detail-statistical-chart/${id}`
      }),
      keepUnusedDataFor: 0
    }),
    getExistCampaigns: build.query<DataResponse<CampaignType[]>, FilterCampaign>({
      query: (params) => ({
        url: '/api/existing-campaigns',
        params
      }),
      keepUnusedDataFor: 0
    }),
    getMailStatusOfLeadDetail: build.query<DataResponse<DetailLeadInCampaign>, MailStatusPayload>({
      query: (params) => ({
        url: `/api/mail-status-of-lead-detail/${params.campaign_id}`,
        params: {
          lead_id: params.lead_id
        }
      })
    }),
    actionsCampaign: build.mutation<DataResponse<CampaignType>, CampaignType>({
      query: (data) => {
        return {
          url: `/api/update-status-campaign/${data.id}`,
          method: 'PUT',
          data
        }
      },
      invalidatesTags: (_, error, body) =>
        error ? [] : [{ type: 'Campaigns', id: body.id as string }]
    }),
    approveLead: build.mutation<DataResponse<CampaignType>, CampaignPayload.Approve>({
      query: (data) => {
        return {
          url: `/api/approve-lead-email-campaign/${data.campaign_email_lead_map_id}`,
          method: 'PUT',
          data
        }
      },
      invalidatesTags: (_, error, body) =>
        error
          ? []
          : [
              { type: 'Campaigns', id: 'LIST' },
              { type: 'LeadInCampaign', id: body.campaign_email_lead_map_id }
            ]
    }),
    approveSelectedLead: build.mutation<DataResponse<CampaignType[]>, CampaignPayload.ApproveAll>({
      query: (data) => {
        return {
          url: `/api/update-lead-campaigns/${data.campaign_email_lead_map_id}`,
          method: 'PUT',
          data
        }
      },
      invalidatesTags: (_, error) => (error ? [] : [{ type: 'Campaigns', id: 'LIST' }])
    }),
    removeLeadInCampaign: build.mutation<any, CampaignPayload.RemoveLead>({
      query: (body) => ({
        url: `/api/remove-campaign-lead/${body.campaignId}`,
        method: 'DELETE',
        data: {
          lead_id: body.leadId,
          id: body.campaignId
        }
      }),
      invalidatesTags: (_, error, body) =>
        error
          ? []
          : [
              { type: 'Campaigns', id: 'LIST' },
              { type: 'LeadInCampaign', id: body.campaignId }
            ]
    }),
    autoPilotProcess: build.mutation<DataResponse<any>, any>({
      query: (body) => ({
        url: '/api/autopilot-campaign-process',
        method: 'POST',
        data: body
      }),
      invalidatesTags: (_, error) => (error ? [] : [{ type: 'Campaigns', id: 'LIST' }])
    }),
    updateAutoPilot: build.mutation<any, CampaignPayload.Add>({
      query: (body) => ({
        url: `/api/campaigns/${body.id}`,
        method: 'PUT',
        data: body
      }),
      invalidatesTags: (_, error, body) =>
        error
          ? []
          : [
              { type: 'Campaigns', id: body.id },
              { type: 'LeadInCampaign', id: body.id }
            ]
    }),
    updateScheduleAction: build.mutation<any, CampaignPayload.Schedule>({
      query: (body) => ({
        url: `/api/action-schedule-campaign-email-lead-map/${body.campaign_management_id}`,
        method: 'PUT',
        data: body
      }),
      invalidatesTags: (_, error, body) =>
        error ? [] : [{ type: 'LeadInCampaign', id: body.campaign_management_id }]
    })
  })
})

export const {
  useGetCampaignsQuery,
  useGetDetailCampaignQuery,
  useLazyGetDetailCampaignQuery,
  useAddCampaignMutation,
  useAddLeadToCampaignMutation,
  useGetDetailLeadInCampaignQuery,
  useLazyGetDetailLeadInCampaignQuery,
  useGetDetailChartQuery,
  useGetExistCampaignsQuery,
  useGetMailStatusOfLeadDetailQuery,
  useActionsCampaignMutation,
  useApproveLeadMutation,
  useApproveSelectedLeadMutation,
  useRemoveLeadInCampaignMutation,
  useAutoPilotProcessMutation,
  useUpdateAutoPilotMutation,
  useUpdateScheduleActionMutation
} = campaignSlice
