import { subscribeAutoPilotChannel, subscribeStripeCheckoutChannel } from '@/middleware'
import { RuntimeContext } from '@/store/context'
import { useAppSelector } from '@/store/hooks'
import { showSuccessNotification } from '@/utils/helpers'
import { useContext, useEffect } from 'react'

const NotifyProvider = () => {
  const context = useContext(RuntimeContext)!
  const { user } = useAppSelector((state) => state.auth)

  const handleLaunch = async () => {
    await context?.runtime.launch()
  }

  useEffect(() => {
    if (user?.id) {
      handleLaunch()
      subscribeAutoPilotChannel.bind(`auto-pilot-${user.id}`, (data: any) => {
        showSuccessNotification({ title: data.message })
      })
      subscribeStripeCheckoutChannel.bind(`stripe-checkout-${user.id}`, (data: any) => {
        showSuccessNotification({ title: data.message })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  return null
}

export default NotifyProvider
