import { store } from '@/store'
import Bugsnag from '@bugsnag/js'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { setupClarityTracking } from './configs/clarityTracking'
import RenderRouter from './routers/RenderRouter'
import previewImage from '@/assets/images/preview.png'

function App() {
  useEffect(() => {
    // Setup everything needed when mounting the App in global level
    setupClarityTracking()
  }, [])
  const ErrorBoundary = (Bugsnag as any).getPlugin('react').createErrorBoundary(React)

  return (
    <BrowserRouter>
      <Helmet defaultTitle='Shift Right'>
        <meta charSet='utf-8' />
        <title>Shift Right</title>
        <meta property='og:image' content={previewImage} />
        <meta
          name='description'
          content='Shift Right is a AI business development rep that performs prospecting and outreaching activities like real human. It searches leads and their companies recent updates, writes personalized emails, and works 24 x 7'
        />
      </Helmet>
      <ErrorBoundary>
        <Provider store={store}>
          <div className='app'>
            <RenderRouter />
          </div>
        </Provider>
      </ErrorBoundary>
    </BrowserRouter>
  )
}

export default App
