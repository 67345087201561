import axiosBaseQuery from '@/store/axiosBaseQuery'
import { Notification } from '@/types/notification.type'
import type { DataResponse, Options } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'

export const notificationSlice = createApi({
  reducerPath: 'notificationSlice',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getNotifications: build.query<DataResponse<Notification.Type[]>, Options>({
      query: (params) => ({
        url: '/api/notification',
        params
      })
    })
  })
})

export const { useGetNotificationsQuery } = notificationSlice
