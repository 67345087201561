import axiosBaseQuery from '@/store/axiosBaseQuery'
import { UserAccount } from '@/types/user.type'
import { DataResponse } from '@/types/utils.type'
import { createApi } from '@reduxjs/toolkit/query/react'

export const userSlice = createApi({
  reducerPath: 'userSlice',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getUsers: build.query<DataResponse<UserAccount.Type[]>, UserAccount.FilterUser>({
      query: (params) => ({
        url: '/api/user/user-management-login-history',
        params
      }),
      keepUnusedDataFor: 0
    })
  })
})

export const { useGetUsersQuery, useLazyGetUsersQuery } = userSlice
