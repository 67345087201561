import { bugsnagNotification } from '@/configs/setupBugSnags'
import { routes } from '@/routers'
import type { FollowUpQuestion } from '@/types/campaign.type'
import type {
  ComposeError,
  DataResponse,
  JsonResponse,
  PayloadErrorMessage,
  Store
} from '@/types/utils.type'
import http, { Serializer } from '@/utils/http'
import { METHOD, REGEX } from '@/utils/variables'
import { NotifiableError } from '@bugsnag/js'
import { FormInstance, notification } from 'antd'
import { AxiosResponse, HttpStatusCode } from 'axios'
import { get, isEmpty, last, size } from 'lodash'
import moment from 'moment'

export function retry<R>(fn: () => Promise<R>, retriesLeft = 5, interval = 1000) {
  return new Promise<R>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded')
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const getTruthyElement = (allValues: Store) => {
  const result: string[] = Object.entries(allValues)
    .filter(([_key, value]) => value !== undefined)
    .map(([_key, value]) => value as string)

  return result
}

export const convertResponseAxios = (response: AxiosResponse) => {
  const { data, meta, parser, title, status, type } = response.data as DataResponse
  const { method } = response.config

  if (
    status === HttpStatusCode.Ok &&
    parser &&
    [METHOD.GET, METHOD.POST, METHOD.PUT].includes(method as string)
  ) {
    const renderData = (data: JsonResponse | JsonResponse[]) => {
      if (Array.isArray(data)) {
        return Serializer.deserialize(type as string, response.data)
      } else {
        return Serializer.deserialize(data.type, { ...response.data, type: data.type })
      }
    }

    return {
      ...response,
      data: {
        data: !parser ? data : renderData(data),
        pagination: meta?.pagination,
        title
      }
    }
  }

  return response
}

export const convertFormData = (data: Store) => {
  let formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

export const showSuccessNotification = <T>(result: DataResponse<T> | { title: string }) => {
  notification.success({
    message: 'Notification',
    description: result.title,
    placement: 'topRight'
  })
}

export const showToastError = <T>(result: DataResponse<T> | { title: string }) => {
  notification.error({
    message: 'Error',
    description: result.title,
    placement: 'topRight'
  })
}

export const showValidationError = (error: PayloadErrorMessage) => {
  notification.error({
    message: error.data.title,
    description: error.data.errors[0].detail,
    placement: 'topRight'
  })
  bugsnagNotification(coreError(error))
}

export const showErrorNotification = (error: PayloadErrorMessage, formRef: FormInstance<any>) => {
  if (error.status === 400 && !isEmpty(error.data)) {
    if (!isEmpty(error.data.errors)) {
      error.data.errors.forEach((item) => {
        formRef.setFields([
          {
            name: get(item, 'source.pointer'),
            errors: [get(item, 'detail')]
          }
        ])
      })
    }
    bugsnagNotification(coreError(error))
  }
}

export const isPayloadErrorMessage = (payload: unknown): payload is PayloadErrorMessage => {
  return (
    typeof payload === 'object' &&
    payload !== null &&
    'data' in payload &&
    typeof (payload as any).data?.title === 'string'
  )
}

export const isValidationError = (payload: unknown): payload is PayloadErrorMessage => {
  return (payload as any)?.status === HttpStatusCode.BadRequest
}

export const isUnauthorizedError = (payload: unknown): payload is PayloadErrorMessage => {
  return (payload as PayloadErrorMessage)?.status === HttpStatusCode.Unauthorized
}

export const trimWhitespace = (array?: string[]) => {
  return array?.map((item) => item.trim()).filter((item) => item !== '') ?? undefined
}

export const convertMarkdownToHtml = (markdown: string) => {
  return markdown
    ?.split('\n\n')
    .map((text) => `<p>${text}</p>`)
    .join('')
}

export const convertIncludes = (include: string[] = []) => {
  if (!isEmpty(include)) {
    return include.join(',')
  }
  return undefined
}

export const convertFollowupData = (data: FollowUpQuestion[] = []) => {
  return data
    .map((item) => {
      if (item.email_type === 'college_alumni') {
        return {
          college: JSON.parse(item.answer).name,
          user_graduation_year: JSON.parse(item.answer).year
        }
      }

      if (item.email_type === 'past_colleague') {
        return {
          past_company: JSON.parse(item.answer).name,
          past_company_year_employment: JSON.parse(item.answer).year
        }
      }

      return {
        [`${item.field}`]: item.answer || ''
      }
    })
    .reduce((prev, curr) => {
      Object.assign(prev, curr)
      return prev
    }, {})
}

export const exportExcel = async <T>(path: string, data: T, nameFile: string) => {
  try {
    const response = await http.post(`${path}`, data, {
      responseType: 'blob'
    })

    if (response.status >= 200 && response.status < 300) {
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = nameFile
      link.click()
    } else {
      notification.error({
        message: 'Error',
        description: 'Failed to export file. Please try again.'
      })
    }
  } catch (error) {
    notification.error({
      message: 'Error',
      description: 'Failed to export file. Please try again.'
    })
  }
}

export const coreError = (error: PayloadErrorMessage): NotifiableError => {
  return {
    name: error?.data?.title ?? 'There is error occurred',
    message:
      error?.data?.errors?.map((err) => err.detail).join(', ') ??
      'Please help to contact our support'
  }
}

export const composeError = (error: ComposeError): NotifiableError => {
  return {
    name: 'Field required',
    message: `Missing ${error?.data?.details?.map((err) => last(err.loc)).join(', ')}` ?? ''
  }
}

export const getListPath = (role: 'ADMIN' | 'USER') => {
  return routes.filter((item) => item.role === role).map((item) => item.path)
}

export const isUUID = (input: string): boolean => {
  const uuidRegex = REGEX.UUID

  return uuidRegex.test(input)
}

export const convertPathname = (pathname: string): string => {
  const arr = pathname.split('/')
  const lastIndex = size(arr) - 1

  if (isUUID(arr[lastIndex])) {
    arr.splice(lastIndex, 1, ':id')
  }

  return arr.join('/')
}

export const isBeforeDate = (date: string): boolean => Boolean(moment(date).isBefore(moment()))

export const formatterUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const spamScoreColor = (value: number) => {
  if (value > 90) {
    return '#00AB82'
  } else if (value > 85) {
    return '#00ABAB'
  } else if (value > 80) {
    return '#DB7600'
  } else if (value > 75) {
    return '#DB4200'
  } else if (value > 70) {
    return '#C62C0A'
  } else if (value > 65) {
    return '#9B1C00'
  } else {
    return 'unset'
  }
}
