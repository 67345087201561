import type { LeadToCampaignPayload } from '@/types/campaign.type'
import type { ScreenType } from '@/types/utils.type'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type ApproveDataType = Pick<Partial<LeadToCampaignPayload>, 'campaign_management_id' | 'user_id'>
type ProgressType = {
  loading: boolean
  totalLead: number
}
type ReconnectType = {
  id: string | null
  active: boolean
}

type State = {
  step: ScreenType | null
  isSkip?: boolean
  approveData: ApproveDataType
  progressLoading: boolean
  totalLead: number
  indexLead: number
  reconnectData: ReconnectType | null
  scenarioId: string | null
}

const initialState: State = {
  step: null,
  isSkip: false,
  approveData: {},
  progressLoading: false,
  totalLead: 0,
  indexLead: 0,
  reconnectData: null,
  scenarioId: null
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    resetApproveEvent: (state) => {
      state.progressLoading = false
      state.totalLead = 0
      state.indexLead = 0
    },
    onSetStep: (state, action: PayloadAction<Pick<State, 'step' | 'isSkip'>>) => {
      state.step = action.payload.step
      state.isSkip = action?.payload.isSkip
    },
    onSetApproveData: (state, action: PayloadAction<ApproveDataType>) => {
      state.approveData = action.payload
    },
    onHandleProgress: (state, action: PayloadAction<ProgressType>) => {
      state.progressLoading = action.payload.loading
      state.totalLead = action.payload.totalLead
    },
    onHandleIndexLead: (state, action: PayloadAction<number>) => {
      state.indexLead = action.payload
    },
    onHandleReconnect: (state, action: PayloadAction<ReconnectType | null>) => {
      state.reconnectData = action.payload
    },
    onSetScenarioId: (state, action: PayloadAction<string | null>) => {
      state.scenarioId = action.payload
    }
  },
  extraReducers: () => {}
})

export const {
  resetApproveEvent,
  onSetStep,
  onSetApproveData,
  onHandleProgress,
  onHandleIndexLead,
  onHandleReconnect,
  onSetScenarioId
} = commonSlice.actions

const commonReducer = commonSlice.reducer
export default commonReducer
