import authApiRequest from '@/apis/auth.api'
import logo from '@/assets/images/logo.svg'
import { bugsnagNotification } from '@/configs/setupBugSnags'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { onGetUserInfo } from '@/store/reducers/auth'
import type { PayloadErrorMessage } from '@/types/utils.type'
import { convertPathname, coreError, getListPath } from '@/utils/helpers'
import { head } from 'lodash'
import React, { useEffect } from 'react'
import type { Location, Params } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import st from './styles.module.scss'

const cookie = new Cookies()

interface IProps {
  children: React.ReactNode
  params?: Readonly<Params<string>>
  location?: Location<any>
  privateRouter: boolean
}

const Layout = (props: IProps) => {
  const { location, privateRouter } = props
  const navigator = useNavigate()

  const dispatch = useAppDispatch()
  const { loading } = useAppSelector((state) => state.auth)

  const userToken = cookie.get('access_token') as string
  const adminToken = cookie.get('access_token_admin') as string
  const isUserRouter = getListPath('USER').includes(convertPathname(location?.pathname!))
  const isAdminRouter = getListPath('ADMIN').includes(convertPathname(location?.pathname!))
  const isDeeplink = location?.pathname.includes('deeplink')
  const isUnsubscribe = location?.pathname.includes('unsubscribe')
  const isPricing = location?.pathname.includes('pricing')

  const getMe = async (token: string) => {
    let response, role
    try {
      response = await authApiRequest.me(token)
      role = head(response?.data.data.info_setting?.user_role)!
      dispatch(onGetUserInfo(response.data.data))
      localStorage.setItem('userInfo', JSON.stringify(response.data.data))
    } catch (error) {
      bugsnagNotification(coreError(error as PayloadErrorMessage))
    }

    return { response, role }
  }

  useEffect(() => {
    // Redirect to home if it's a private route without a user or admin token
    if (privateRouter && !userToken && !adminToken) {
      navigator('/')
      return
    }

    // Handle deep link navigation
    if (isDeeplink) {
      navigator(`${location?.pathname}${location?.search}`)
      return
    }

    if (isUnsubscribe) return

    // Function to handle user token actions
    const handleUserToken = async () => {
      const { response, role } = await getMe(userToken)
      if (response?.data.data.status === 'INACTIVE') {
        navigator('/pricing')
        return
      }

      // Redirect to dashboard if user role is 'USER' and not on a user route or is on pricing page
      if (
        (role === 'USER' && response && !isUserRouter) ||
        (isPricing && response?.data.data.status !== 'EXPIRED')
      ) {
        navigator('/dashboard')
      }
    }

    // Function to handle admin token actions
    const handleAdminToken = async () => {
      const { response, role } = await getMe(adminToken)
      // Redirect to admin user page if admin role is 'ADMIN' and not on an admin route
      if (role === 'ADMIN' && response && !isAdminRouter) {
        navigator('/admin/user')
      }
    }

    // Check user token and perform related actions
    if (userToken) handleUserToken()

    // Check admin token and perform related actions
    if (adminToken) handleAdminToken()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, adminToken, location?.pathname])

  const isLoggedIn = (userToken || adminToken) && !privateRouter // Condition 1: user or admin is logged in and it is not a private route
  const isAppLoading = loading && privateRouter // Condition 2: the application is loading and it is a private route

  if ((isLoggedIn || isAppLoading) && !isDeeplink && !isUnsubscribe) {
    return (
      <div className={st.preload}>
        <img src={logo} alt='logo' />
      </div>
    )
  }

  return <>{props.children}</>
}

export default Layout
